<template>
    <div class="resources">
        <!-- <h1>各品类演艺资源</h1> -->
        <img src="https://img.youstarplanet.com/website/caseShow/CaseShow.png" alt="">
        <div class="navigation" @click="CaseShow"><span>案例展示 > 各品类演艺资源</span></div>
        <ul class="content">
            <li v-for="(item,index) in content" :key="index" @mouseover="move(item)" @mouseleave="removed(item)">
                <div>
                    <img :src="item.img" alt="">
                    <div class="lntroduction" v-show="item.state">{{ item.lntroduction }}</div>
                </div>
                <div class="content_title">{{ item.title }}</div>
            </li>
        </ul>
    </div>
</template>

<script>
export default{
    data() {
        return {
            content:[
                {
                    img: "https://img.youstarplanet.com/website/caseShow/resources1.png",
                    title: '曲艺类',
                    lntroduction: '相声、评书、鼓词、单弦、清音、渔鼓、南音、坠子、琴书、数来宝、快板、山东快书、苏州弹词等...',
                    state: false
                },{
                    img: "https://img.youstarplanet.com/website/caseShow/resources2.png",
                    title: '戏曲类',
                    lntroduction: '京剧、昆曲、粤剧、越剧、黄梅戏、豫剧、评剧、淮剧、川剧、秦腔、汉剧、桂剧、河北梆子等...',
                    state: false
                },{
                    img: "https://img.youstarplanet.com/website/caseShow/resources3.png",
                    title: '舞台剧类',
                    lntroduction: '读剧、默剧、儿童剧、话剧、舞剧、歌剧、音乐剧等...',
                    state: false
                },{
                    img: "https://img.youstarplanet.com/website/caseShow/resources4.png",
                    title: '音乐会类',
                    lntroduction: '器乐SOLO、室内乐、民乐、交响、演唱会等...',
                    state: false
                },{
                    img: "https://img.youstarplanet.com/website/caseShow/resources5.png",
                    title: '晚会类',
                    lntroduction: '小戏小品、彩唱清唱、曲艺、歌舞等...',
                    state: false
                },{
                    img: "https://img.youstarplanet.com/website/caseShow/resources6.png",
                    title: '影视剧类',
                    lntroduction: '广播剧、微电影、连续剧、电影等...',
                    state: false
                },{
                    img: "https://img.youstarplanet.com/website/caseShow/resources6.png",
                    title: '艺术展览类',
                    lntroduction: '设计展、画展、服装展、灯光展、妆造展、道具展、新技术应用展、数字展、艺术舞台、衍生品展等...',
                    state: false
                },
            ]
        }
    },
    methods: {
        move(item){
            item.state = true
        },
        removed(item){
            item.state = false
        },
        CaseShow(){
            this.$router.push({path:`/caseshow`})
        }
    },
    async mounted() {
        document.documentElement.scrollTop = 0
    },
}
</script>

<style lang="less">
.resources{
    &>img{
        width: 100%;
        object-fit:cover;
    }
    .navigation{
        height: 48px;
        line-height: 48px;
        background-color: #F1F1F1;
        color: #8A8A8A;
        font-size: 14px;
        position: relative;
        top: -4px;
        span{
            display: block;
            width: 1270px;
            margin: 0 auto;
        }
    }
    .content{
        width: 1270px;
        margin: 0 auto;
        margin-bottom: 200px;
        display: flex;
        flex-wrap: wrap;
        li{
            position: relative;
            margin-top: 50px;
            &:nth-child(2n){
                margin-left: 32px;
            }
            div{
                &:nth-child(1){
                    width: 619px;
                    height: 317px;
                    overflow:hidden;
                    position: relative;
                }
            }
            img{
                width: 619px;
                height: 317px;
            }
            .content_title{
                width: 619px;
                height: 92px;
                line-height: 92px;
                text-align: center;
                font-size: 26px;
                font-weight: 400;
                background-color: #F8F8F8;
            }
            .lntroduction{
                width: 619px;
                height: 317px;
                background-color: #FF4747;
                padding: 130px 66px 0;
                display: flex;
                flex-wrap: wrap;
                opacity: 0.65;
                position: absolute;
                top: 0px;
                color: #FFFFFF;
                font-size: 18px;
                font-weight: 400;
                
                animation:myfirst 1s;
                -moz-animation:myfirst 1s; 
                -webkit-animation:myfirst 1s; 
                -o-animation:myfirst 1s; 
                animation-fill-mode:forwards;

                @keyframes myfirst{
                    0%   {top:619px;}
                    100% {top:0;}
                }
            }
        }
    }
}
</style>